import React from "react"
import BlogTitle from "../../components/BlogTitle"
import Container from "../../components/Container"
import Hero from "../../components/Hero"
import HeroLead from "../../components/HeroLead"
import HeroTitle from "../../components/HeroTitle"
import Layout from "../../components/Layout"
import Lead from "../../components/Lead"
import SEO from "../../components/SEO/SEO"
import SubTitle from "../../components/SubTitle"
import Text from "../../components/Text"
import links from "../../content/links"
import metaImg from "../../images/laitteetbg.svg"

export default (): JSX.Element => (
  <Layout>
    <SEO
      title="Mitä mobiilisovelluksen kehittäminen maksaa?"
      description="Tässä artikkelissa käymme läpi, mistä mobiilisovelluksen hinta määräytyy ja kuinka paljon mobiilisovellus maksaa."
      pageImage={metaImg}
      pageUrl={links.blogSites.whatApplicationCosts}
    />

    <section id="hero">
      <Hero hideContact>
        <HeroTitle>
          <h1>
            <span className="block">Mitä mobiilisovelluksen</span>
            <span className="block text-indigo-400">kehittäminen maksaa?</span>
          </h1>
        </HeroTitle>
        <HeroLead>Kirjoittanut: Jaakko Lamminpää</HeroLead>
      </Hero>
    </section>

    <section>
      <Container>
        <Text>
          Nopea Google-haku antaa kymmenittäin sivuja, joissa käydään läpi
          mobiilisovellusten hintoja, ainakin siis englanniksi. Miksi sitten
          kirjoittaa samasta aiheesta yhtä lisäkertomusta muiden joukkoon?
          Käytännössä kaikki löytyvät sivut ovat suurten ulkomaisten yritysten
          kertomuksia, mitä heidän hinnoittelullaan sovellus maksaa kehittää.
          Hulvatonta tästä tekee, että he viittaavat toistuvasti
          hinnoittelussaan ja markkinoinnissaan sovelluksiin kuten Facebook
          messenger, Twitter tai Instagram. Näillä maailman suurimmilla
          sovelluksilla ei nyt vain satu olemaan kauheasti tekemistä edes hyvin
          menestyvän sovelluksen kanssa. Ne ovat sovellusmaailman isoimpia ja
          käytetyimpiä tuotteita, niiden kehitykseen ollaa käytetty vuosia
          ohjelmointi ja suunnittelu työtä. Huomioitavaa englanninkielisille
          lähteille on myös, sovellukset joista blogeissa kirjoitetaan ovat
          suunnattu yleensä Yhdysvaltojen ja Iso-Britannian markkinoille, jossa
          kilpailu sovellus markkinoilla on huomattavasti kovempaa ja että
          Suomessa ohjelmistokehitys on yleisesti verrattain edullista. Tietysti
          sovellusta suunniteltaessa on hyvä muistaa että kehittäminen ei ole
          välttämättä ainoa kuluerä tai välttämättä suurinkaan kuluerä sovellus
          julkaisua suunniteltaessa.
        </Text>

        <BlogTitle>Suoraa hintaa on vaikea sanoa</BlogTitle>
        <Text>
          Kiinteän hintalapun laittaminen yleisesti sovellukselle on enemmän tai
          vähemmän kyseenalaista. Kysytään lukijalta, että mitä sinun mielestäsi
          auto maksaa? Näyttävä uusi Mercedes-Benz lisävarustelulla. Hinta:
          kymmeniä tuhansia. Vähän käytetty ja luotettava auto: kymmenen
          tuhatta. Viimeisiään vetelevä ruostunut syöksysämpylä lähtee varmasti
          taskurahalla. Hinta vaihtelee laidasta laitaan ja tärkeintä on se,
          mitä sinä autoltasi odotat ja edellytät.
        </Text>

        <BlogTitle>Mitä maksaa?</BlogTitle>
        <Text>
          Vaikka on vaikea sanoa suoria hintoja sovelluksille, voimme antaa
          arvion siitä mitä meidän kehittämämme sovellukset suurin piirtein
          maksavat. Käytännössä tuotteita saa valmistettua kolmella tasolla ja
          jokaisella kehitysasteella on päämääränsä. Ne tähtäävät aina vision
          esittelystä valmiin hiotun tuotteen valmistukseen.
        </Text>

        <BlogTitle>Prototyyppi</BlogTitle>
        <Text>
          Halvimpana on prototyypin luominen sovelluksesta ja se maksaa noin
          5000 euroa. Prototyyppi tarkoittaa, että kehitämme sovelluksen, joka
          tekee halutut toiminnot. Tämä tehdään ilman mitään hiontaa tai
          kaunistelua, mutta ohjelmisto toimii. Käytännössä kyseessä on
          äärimmäisen raakaversio, joka todistaa ohjelmiston toimivuuden ja
          idean, mutta se ei ole julkaisukelpoinen. Miksi kukaan sitten haluaisi
          prototyypin? Se sopii esimerkiksi yrityksille, jotka haluavat kokeilla
          jotain uutta ja esitellä tuotetta sijoittajille. Olemassa olevalla,
          toimivalla tuotteella on helpompi kerätä rahoittajia taakseen tai
          esitellä pidemmälle vietyä ideaa tehokkaammin kuin kaunopuheilla ja
          ajatuksilla.
        </Text>

        <BlogTitle>MVP</BlogTitle>
        <Text>
          Seuraavana hintaluokassa on MVP. Se on ammattikäytössä oleva termi,
          joka tulee sanoista minimum viable product. Käytännössä kyseessä on
          siis pienin mahdollinen julkaisuvalmis ohjelmisto ja tälle tasolle
          kehitetty sovellus maksaa noin 8 000 – 15 000 euroa. Se on uusi,
          markkinoille vasta yrittävä tuote, joka tähtää tyydyttämään varhaisten
          asiakkaiden tarpeet. Samalla tuotteen tarkoitus on kerätä palautetta
          tuotekehitystä varten. Yritykselle palautteen kerääminen tuotteesta on
          usein edullisempaa kuin enemmän ominaisuuksia sisältävän tuotteen
          täysimittainen sisäinen kehitys. Enemmän ominaisuuksia sisältävän
          tuotteen kehittäminen lisää kustannuksia ja riskejä, koska oletukset
          käyttäjien tarpeista voivat toisinaan osoittautua virheellisiksi.
          Samalla kyetään vastaamaan tehokkaammin käyttäjien toiveisiin ja
          tarpeisiin tuotekehityksen avulla.
        </Text>

        <BlogTitle>Valmis sovellus</BlogTitle>
        <Text>
          Valmiiksi hiottu sovellus maksaa 15 000 – 30 000 euroa. Se sisältää
          täydellisen tuotekehityksen ja testauksen. Lopputuloksena saat valmiin
          ja näyttävän sovelluksen joka varmasti täyttää sille asetetut
          odotukset. Samalla varmistat, että sovellus on käyttäjäystävällinen ja
          vältyt mahdollisilta lisäkuluilta, joita muutosten tekeminen saattaisi
          aiheuttaa. Mutta mitä kaikkea sisältyy valmiin sovelluksen
          valmistamiseen?
        </Text>

        <BlogTitle>Mistä asiakas maksaa?</BlogTitle>
        <Text>
          Seuraavaksi käymme läpi mitä kaikkea sovelluskehitys pitää sisällään
          ja mihin se maksettu raha oikeasti menee. On tärkeää muistaa, että eri
          kokoiset yritykset ja eri maissa sijaitsevat yritykset veloittavat
          erilaisten taksojen avulla. Kuitenkin hinnat kasaantuvat aina samoista
          rakennuspalikoista joka yrityksessä.
        </Text>
        <Text>
          Päinvastoin kuin voisi olettaa, niin usein pienemmät yritykset
          tarjoavat tuotteitaan halvemmalla, koska he pystyvät toimimaan
          notkeammin. Totuus on myös, että pienyritysten on kilpailtava suurten
          yritysten kanssa, joilla on valmiiksi jo laaja asiakaskunta. Pienten
          yritysten etuna taas on, että ne kykenevät tarjoamaan
          henkilökohtaisempaa palvelua ja kehitystä.
        </Text>
        <Text>
          Vaikka moni mobiilisovellus voidaankin ladata ilmaiseksi, ei kannata
          erehtyä luulemaan, että sovelluksen saa samalla hinnalla. Vanha
          viisaus pitää edelleen paikkansa – halvalla saa juuri sitä mitä
          odottaa voi. Asiaa vähemmän tietävälle sovelluksen luominen on pelkkää
          koodin kirjoittamista ja työ on valmis. Vaikka koodaus onkin iso osa
          työtä, se ei ole suoraviivaista koodirivien kirjoittamista. Lisäksi
          kehittäminen vaatii myös esimerkiksi visuaalisen ilmeen suunnittelua
          ja käyttäjäystävällisyyden kehittämistä.
        </Text>

        <BlogTitle>Alustat</BlogTitle>
        <Text>
          Nykyisin käytössä on enää vain kaksi varteenotettavaa
          käyttöjärjestelmää: iOS ja android. Käytännössä puhutaan siis Applesta
          ja muista yleisimmistä puhelimista. 2016 vuonna myydyistä puhelimista
          81,7 prosenttia oli android ja 17,9 prosenttia iOS-alustalla.Vielä
          muutama vuosi sitten Windowsia pidettiin nousevana tekijänä, mutta sen
          ja muiden puhelinten osuu markkinoista oli vain 0,4 prosenttia.
        </Text>
        <Text>
          Käytännössä näille kahdelle alustalle koodattuna sovellus tavoittaa
          lähes kaikki ihmiset eikä muita kilpailijoita näy lähitulevaisuudessa.
          iOS ja android käyttävät kuitenkin eri koodikieliä eli koodaaminen
          molemmille alustoille ei onnistu aivan niin suoraviivaisesti kuin
          olisi toivottavaa. Vähemmän yllättäen toisen koodin kirjoittaminen
          vaatii hieman lompakon raottamista, mutta on usein sen arvoista.
        </Text>

        <BlogTitle tag="h3">Native</BlogTitle>
        <Text>
          Suoraviivaisin ratkaisu koodamiseen on native. Se tarkoittaa alustalle
          tarkoitettua alkuperäistä kieltä, jolla onnistutaan tuottamaan kaikki
          mahdolliset toiminnot. Valitettavasti tämä ei käänny suoraan toisille
          alustoille ja vaikka koodi muistuttaakin toista, vie sen muuttaminen
          toiselle alustalle lähes yhtä pitkään kuin puhtaasta projektista
          aloittaminen. Aikaisemmin on ollut aihetta pohtia kummalle alustalle
          sovellus suunnitellaan. Moni yritys valmisti aluksi sovelluksen
          toiselle alustalle ja myöhemmin suosion kasvaessa käänsi sovelluksen
          myös muille alustoille.
        </Text>

        <BlogTitle tag="h3">Hybrid</BlogTitle>
        <Text>
          Nykyisin käytössä on onneksi hybridi-pohjat, jotka tekevät
          mahdolliseksi sovelluksen luonnin molemmille alustoille
          samanaikaisesti. Tämä ei kuitenkaan ole täysin ongelmatonta. Siinä
          missä hybridi-alusta onnistuu kääntämään suuren osan toiminnoista
          suoraan eri native-kielille, osa toiminnoista joudutaan yhä koodaamaan
          erikseen. Hybridi-alustalla koodaaminen on pätevä ratkaisu, mikäli
          sovellus pitäytyy kaikkein tavallisimmissa ja yksinkertaisimmissa
          ominaisuuksissa. Vaikeiden toimintojen koodaaminen joudutaan yleensä
          suorittamaan alustan omalla kielellä ja se tuo taas lisää työtunteja.
        </Text>

        <BlogTitle tag="h3">React native</BlogTitle>
        <Text>
          Kuluttajien onneksi tunnelin päässä on valoa. Vähän aikaa sitten
          julkaistiin REACT Native – ensimmäinen potentiaalinen mahdollisuus
          todelliselle hybridi-alustalle. Siinä missä aikaisemmat
          hybridi-alustat ovat kyenneet kääntämään sovelluksia osittain, REACT
          Nativen avulla pystytään kääntämään lähes koko sovellus suoraan
          molemmille alustoille. Lisäksi kaikki mitä ei saada käännettyä,
          voidaan koodata suoraan natiivina sovellukseen. Käytännössä tämä tulee
          lähivuosina tekemään muista hybrid alustoista lähes tarpeettomia.
        </Text>
        <Text>
          Valitettavasti REACT Native on sen verran tuore uutuus, että kaikista
          ohjelmistotaloista React Nativea ohjelmoivia koodaajia ei löydy.
          Mikäli siis projekti on mahdollista kirjoittaa React Nativella, on
          kahdelle alustalle julkaistava sovellus yleensä halvempi.
        </Text>

        <BlogTitle tag="p">Plussat ja miinukset</BlogTitle>

        <div className="grid grid-cols-3">
          <div>
            <Lead tag="p" additionalClassNames="mb-3">
              Native
            </Lead>
            <div className="pl-3">
              <SubTitle tag="p" additionalClassNames="mb-2">
                Plussat
              </SubTitle>
              <ul className="list-disc text-gray-700 ml-7">
                <li>Kaikki firmat tarjoavat</li>
                <li>Täydellinen ominaisuuksien hallinta</li>
              </ul>
              <SubTitle tag="p" additionalClassNames="mb-2 mt-2">
                Miinukset
              </SubTitle>
              <ul className="list-disc text-gray-700 ml-7">
                <li>Pitkä kehitys</li>
                <li>
                  Kahdelle alustalle tehtäessä lähes kaksinkertaistaa hinnan.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <Lead tag="p" additionalClassNames="mb-3">
              Hybridi
            </Lead>
            <div className="pl-3">
              <SubTitle tag="p" additionalClassNames="mb-2">
                Plussat
              </SubTitle>
              <ul className="list-disc text-gray-700 ml-7">
                <li>Monet yritykset tarjoavat</li>
                <li>Halvempaa kuin natiivi sovelluksen kehittäminen</li>
              </ul>
              <SubTitle tag="p" additionalClassNames="mb-2 mt-2">
                Miinukset
              </SubTitle>
              <ul className="list-disc text-gray-700 ml-7">
                <li>Kaikki ominaisuudet eivät käänny suoraan </li>
              </ul>
            </div>
          </div>
          <div>
            <Lead tag="p" additionalClassNames="mb-3">
              React native
            </Lead>

            <div className="pl-3">
              <SubTitle tag="p" additionalClassNames="mb-2">
                Plussat
              </SubTitle>
              <ul className="list-disc text-gray-700 ml-7">
                <li>Sovellus samalla kielellä molemmille alustoille</li>
              </ul>
              <SubTitle tag="p" additionalClassNames="mb-2 mt-2">
                Miinukset
              </SubTitle>
              <ul className="list-disc text-gray-700 ml-7">
                <li>Taitavia React Native yrityksiä hieman vaikea löytää.</li>
              </ul>
            </div>
          </div>
        </div>

        <BlogTitle>Ohjelmoinnin pituus ja hankaluus</BlogTitle>
        <Text>
          Käytämme paljon avoimen lähdekoodin kirjastoja päivittäisessä
          ohjelmistotyössä. Tämä vaikuttaa helposti siltä, että sovelluksen
          kehittäminen on nopeaa, koska tarjolla on paljon avointa ja vapaasti
          hyödynnettävää koodia. Avointa koodia voidaan kuitenkin vain harvoin
          hyödyntää suoraan ohjelmistossa. Valmiita koodinpätkiä joudutaan usein
          muokkaamaan ja integroimaan valmistettavaan sovellukseen.Valmis
          sovellus on myös enemmän kuin osiensa summa. Eri ominaisuuksien
          liittäminen toisiinsa toimivaksi kokonaisuudeksi vaatii suunnittelua
          ja työtä.
        </Text>
        <Text>
          Ohjelmointi on luovaa suunnittelua siinä missä esimerkiksi
          arkkitehdinkin työ. Oikeanlaisten rakenteiden luominen tai halutun
          visuaalisen asun saavuttaminen vaatii huolella suoritettua
          suunnittelua ennen ohjelmointia. Mitä tämä tarkoittaa käytännössä?
          Vaikka tarkasti laadittu malli olisikin valmiina, joudutaan
          visuaalista ilmettä muokkaamaan ja korjailemaan usein, käytön
          parantamiseksi. Esimerkiksi valikon siirtäminen toiseen paikkaan
          vaatii visuaalisen ilmeen uudelleen rakentamisen. Ulospäin tämä vaihe
          näyttää usein siltä, että mitään ei oikeastaan tapahdu. Sovellus ei
          näytä kehittyvän. Valmista visuaalista ilmettä ei ole. Todellisuudessa
          koodia on voitu muuttaa ja parantaa satoja, jopa tuhansia kertoja,
          mutta sovellus ei vieläkään ole valmis. Vaikka halu on kova,
          kiirehtiminen ei kannata. Kukaan ei halua ladata keskeneräistä
          sovellusta ja usein virheiden korjaaminen jälkikäteen huomattavan
          kalliiksi.
        </Text>

        <BlogTitle>Eri ominaisuudet</BlogTitle>
        <Text>
          Miksi on vaikea antaa suoraan hintaa sovelluksen kehittämisestä?
          Sovellus koostuu käytännössä vain erilaisista ominaisuuksista, mutta
          näitä voidaan liittää toisiinsa äärettömän monella tavalla. Halvimpia
          ja yksinkertaisimpia koodattavia ovat laskemista ja lukemista
          edellyttävät sovellukset. Esimerkiksi sovellus, joka pitää kirjaa
          varastossa olevista tuotteista ja vähentää niitä saldosta aina
          ostettaessa. Sovelluksen luominen vaikeutuu heti moninkertaisesta, kun
          halutaan monimutkaisempia toimintoja, vaikka usein ne ulospäin
          vaikuttaisivat helpoilta. Esimerkiksi Facebookin Messenger- ja
          Snapchat-sovelluksessa pystyt jo kameran etsintilassa muokkaamaan
          kuvaa ja sovellus antaa sinulle mahdollisuuden lisätä itsellesi
          esimerkiksi vaikka hatun ja aurinkolasit päähän. Todellisuudessa tämä
          vaatii kuitenkin toimivan tekoälyn luomista. Sovelluksen on kyettävä
          erottelemaan mitkä ovat ihmisen kasvot ja missä ovat kasvojen
          äärirajat. Vaikka ihmiselle erottaminen on suoraviivaista, sen
          kirjoittaminen ohjelmistoon on äärimmäisen vaativaa.
        </Text>
        <Text>
          Tietyt ominaisuudet eivät aina vaadi äärimmäisen taidokasta ja aikaa
          vievää koodausta, mutta aikaa kuluu muuhun suunnitteluun. Haluatko
          esimerkiksi luoda myyntisovelluksen ja markkinoida sen avulla
          tuotteitasi käyttäjille? Mistä tiedät mitä sinun kannattaa mainostaa
          käyttäjälle? Sovelluksen avulla voit seurata mitä tuotteistasi hän
          katselee. Kyse ei ole kuitenkaan pelkästä koodista, vaan
          ostotottumusalgoritmin luomisesta. Se on monimutkaista ja käytännössä
          vaatii pitkäaikaisempaa kehitystä toimiakseen tehokkaasti.
        </Text>
        <BlogTitle>Testaaminen</BlogTitle>
        <Text>
          Testaamalla voimme varmistaa, että sovellus on toimiva ja
          käyttäjäystävällinen. Se voi jopa auttaa vastaamaan paremmin
          käyttäjien tarpeisiin. Prototyypin testaaminen onkin tärkeä osa minkä
          tahansa tuotteen kehitystyötä. Tämä voi tapahtua esimerkiksi
          julkaisemalla alustava versio halutulle kohderyhmälle tai pienemmissä
          tapauksissa riittää jopa, että läheiset ja ystävät testaavat
          sovellusta. Näistä saatava palaute auttaa kehittämään sovelluksen
          käytettävyyttä suunnattomasti, mutta se vie myös aikaa ja työtunteja.
          Ei kuitenkaan kannata pelätä prototyypin uudelleen muovaamista niin
          usein kuin tarpeellista. Käytännössä ei ole järkevää koodata valmiiksi
          mitään puoliksi toimivaa ja suunniteltua, jos se ei oikeasti miellytä
          käyttäjiä.
        </Text>
        <BlogTitle>Palvelimet ja BackEnd</BlogTitle>
        <Text>
          Yksinkertaisimmat sovellukset toimivat suoraan kännykällä eivätkä
          vaadi mitään ylimääräistä. Mutta esimerkiksi pelkkä kirjautuminen
          sovellukseen vaatii jo paljon enemmän. Käytännössä mikään ei toimi
          ilman tietokannan luomista sekä tiedon siirtämistä ja käsittelyä
          verkossa. Tämä vaatii serverin datan tallennukseen ja käsittelyyn.
          Ammattislangissa puhutaan BackEndistä. Luonnollisempaa on puhua
          taustaohjelmistosta, joka hallinnoi serverillä olevaa
          tietokantatietokantaaa.
        </Text>
        <Text>
          Taustaohjelmiston avulla serverillä tai pilvessä säilytettyjä tietoja
          voidaan integroida osaksi sovellusta. Esimerkiksi sovellus voidaan
          saada tallentamaan tilauksesta saatavat tiedot palvelimelle, josta ne
          siirretään aina päivittyessään käyttäjälle. Näin asiakas voi seurata
          tilauksensa edistymistä reaaliajassa. Yleensä tietokantojen ja
          taustaohjelmistojen luominen sekä servereiden ylläpitäminen ovat osa
          hinnastoa, mutta hinta vaihtelee paljon riippuen vaatimuksista.
          Tarvittaessa voidaan hyödyntää asiakkaan omiakin servereitä mikäli
          sellaiset ovat tarjolla.
        </Text>
        <BlogTitle>Muutakin kuin pelkkä ohjelmisto</BlogTitle>
        <Text>
          Kuten aikaisemmin mainitsimme, niin sovelluksen kehittäminen on paljon
          enemmän kuin pelkkää koodin kirjoittamista. Samoin sovellus on paljon
          enemmän kuin pelkkä ohjelmisto. Kaikki menestyvät sovellukset ovat
          toimivuutensa lisäksi myös näyttäviä ja käyttäjäystävällisiä. Ne
          tarjoavat jotain mitä käyttäjät haluavat. Tällaisen sovelluksen
          luominen harvoin onnistuu yhdeltä ihmiseltä. Siksi käytössä onkin
          usein tiimi, joka koostuu eri alojen osaajista. Osa koodareista on
          erikoistunut tietokantojen luomiseen, toiset taas
          ohjelmistokehitykseen ja toiset analytiikkaan. Visuaalisen ilmeen
          suunnittelu edellyttää myös paljon muuta kuin tuhat riviä koodia.
          Käytännössä halutaan yhdistää visuaalisen suunnittelijan huikea
          näkemys siihen mikä on käyttäjäystävällistä ja mikä on
          toteutettavissa.
        </Text>
      </Container>
    </section>
  </Layout>
)
